import cx from "classnames"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import React from "react"
import { Dropdown, Nav, Navbar, NavItem, NavLink } from "react-bootstrap"
import Imgix from "react-imgix"
import {
  aboutUsPath,
  careersPath,
  contactUsPath,
  customerPortalUrl,
  deliveryReceiptPath,
  haullyURL,
  homePath,
  individualVehicleMovesPath,
  leadershipTeamPath,
  loginURL,
  newVehicleMovesPath,
  pilotTransportURL,
  remarketedVehicleMovesPath,
  specialtyVehicleMovesPath,
  teamDriveAwayURL,
} from "../paths"
import { titleToAnchorId } from "../utils/titleToAnchorId"
import styles from "./stylesheets/desktopHeader.module.scss"

export default function DesktopHeader({
  activePage,
  bottomBorder,
  remarketedVehicleSectionTitles,
  newVehicleSectionTitles,
  unitedRoadHeaderLogo,
  haullyLogo,
  pilotTransportLogo,
  teamDriveAwayLogo,
}) {
  const [openAboutUs, setOpenAboutUs] = React.useState(false)
  const [aboutUsMenuHover, setAboutUsMenuHover] = React.useState(false)
  const [openRemarketed, setOpenRemarketed] = React.useState(false)
  const [remarketedMenuHover, setRemarketedMenuHover] = React.useState(false)
  const [openPersonal, setOpenPersonal] = React.useState(false)
  const [personalMenuHover, setPersonalMenuHover] = React.useState(false)
  const [openNewVehicle, setOpenNewVehicle] = React.useState(false)
  const [newVehicleMenuHover, setNewVehicleMenuHover] = React.useState(false)

  return (
    <div className="header d-none d-lg-flex flex-column">
      <div className="bg-dark">
        <div className={styles.topLevelContainer}>
          <Navbar className={cx(styles.topNavBar, "p-0 w-100")}>
            <Nav className="ml-auto h-100 p-0">
              <Dropdown
                className={cx(
                  "d-flex align-items-center justify-content-center text-white font-weight-bold border-0",
                )}
                show={openAboutUs || aboutUsMenuHover}
              >
                <Dropdown.Toggle
                  className={cx(
                    styles.topNavLink,
                    styles.topNavToggle,
                    "text-center d-flex align-items-center justify-content-center border-top-0 border-bottom-0 border-right-0 h-100 border-left border-secondary",
                    activePage === "about" || activePage === "leadership-team"
                      ? styles.activeTopHeader
                      : "text-secondary bg-black",
                  )}
                  onMouseEnter={() => setOpenAboutUs(true)}
                  onMouseLeave={() => setOpenAboutUs(false)}
                  onKeyDown={() => setOpenAboutUs(true)}
                  onKeyUp={() => setOpenAboutUs(false)}
                >
                  About Us
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className={cx(
                    styles.subheaderMenuList,
                    "py-0 font-weight-normal mt-0",
                  )}
                  onMouseEnter={() => setAboutUsMenuHover(true)}
                  onMouseLeave={() => setAboutUsMenuHover(false)}
                >
                  <Link
                    className={cx(
                      styles.subheaderMenuItem,
                      styles.subheaderBorder,
                    )}
                    to={aboutUsPath()}
                  >
                    About United Road
                  </Link>
                  <Link
                    className={cx(
                      styles.subheaderMenuItem,
                      styles.subheaderBorder,
                    )}
                    to={leadershipTeamPath()}
                  >
                    Leadership Team
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
              <Link
                className={cx(
                  styles.topNavLink,
                  "border-left border-secondary text-center d-flex align-items-center justify-content-center",
                  activePage === "careers"
                    ? styles.activeTopHeader
                    : "text-secondary",
                )}
                to={careersPath()}
              >
                Careers
              </Link>
              <Link
                className={cx(
                  styles.topNavLink,
                  "border-left border-secondary text-center d-flex align-items-center justify-content-center",
                  activePage === "contact"
                    ? styles.activeTopHeader
                    : "text-secondary",
                )}
                to={contactUsPath()}
              >
                Contact Us
              </Link>
              <OutboundLink
                className={cx(
                  styles.topNavLink,
                  "text-secondary border-left border-secondary text-center d-flex align-items-center justify-content-center",
                )}
                href={loginURL()}
                target="_blank"
                rel="noopener noreferrer"
              >
                OVISS Login
              </OutboundLink>
              <Dropdown
                className={cx(
                  "d-flex align-items-center justify-content-center text-white border-0 bg-green",
                )}
              >
                <OutboundLink
                  className={cx(
                    styles.topNavHighlight,
                    "bg-green h-100 border-left border-secondary text-center d-flex align-items-center justify-content-center",
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={customerPortalUrl()}
                >
                  Customer Portal
                </OutboundLink>
              </Dropdown>
            </Nav>
          </Navbar>
        </div>
      </div>
      <div className={bottomBorder && styles.bottomBorder}>
        <Navbar
          className={cx(
            styles.topLevelContainer,
            "d-flex flex-row justify-content-around px-3 py-3 w-100",
          )}
        >
          <Link to={homePath()} className={styles.subheaderLink}>
            <Imgix
              src={unitedRoadHeaderLogo.url}
              className={styles.subheaderMainLogo}
              alt="United Road Logo"
              width={290}
            />
          </Link>

          <Dropdown as={NavItem} show={openRemarketed || remarketedMenuHover}>
            <Dropdown.Toggle
              onMouseEnter={() => setOpenRemarketed(true)}
              onMouseLeave={() => setOpenRemarketed(false)}
              onKeyDown={() => setOpenRemarketed(true)}
              onKeyUp={() => setOpenRemarketed(false)}
              as={NavLink}
              className={cx(
                styles.subheaderLink,
                styles.subheaderTopLevelMenu,
                activePage === "remarketed-vehicle-moves" &&
                  styles.activeHeader,
              )}
              href={remarketedVehicleMovesPath()}
            >
              Remarketed <br />
              Vehicle Moves
            </Dropdown.Toggle>
            <Dropdown.Menu
              onMouseEnter={() => setRemarketedMenuHover(true)}
              onMouseLeave={() => setRemarketedMenuHover(false)}
              className={cx(
                styles.subheaderMenuList,
                styles.offsetMenuList,
                "py-0",
              )}
            >
              {remarketedVehicleSectionTitles.map((sectionTitle) => {
                return (
                  <Dropdown.Item
                    key={sectionTitle}
                    className={cx(
                      styles.subheaderMenuItem,
                      styles.subheaderBorder,
                    )}
                    href={`${remarketedVehicleMovesPath()}#${titleToAnchorId(
                      sectionTitle,
                    )}`}
                  >
                    {sectionTitle}
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown as={NavItem} show={openNewVehicle || newVehicleMenuHover}>
            <Dropdown.Toggle
              onMouseEnter={() => setOpenNewVehicle(true)}
              onMouseLeave={() => setOpenNewVehicle(false)}
              onKeyDown={() => setOpenNewVehicle(true)}
              onKeyUp={() => setOpenNewVehicle(false)}
              href={newVehicleMovesPath()}
              as={NavLink}
              className={cx(
                styles.subheaderLink,
                styles.subheaderTopLevelMenu,
                activePage === "new-vehicle-moves" && styles.activeHeader,
              )}
            >
              New <br />
              Vehicle Moves
            </Dropdown.Toggle>
            <Dropdown.Menu
              onMouseEnter={() => setNewVehicleMenuHover(true)}
              onMouseLeave={() => setNewVehicleMenuHover(false)}
              className={cx(
                styles.subheaderMenuList,
                styles.offsetMenuList,
                "py-0",
              )}
            >
              <Dropdown.Item
                key={"oem"}
                className={cx(styles.subheaderMenuItem, styles.subheaderBorder)}
                href={newVehicleMovesPath()}
              >
                OEM
              </Dropdown.Item>
              {newVehicleSectionTitles.map((sectionTitle) => {
                return (
                  <Dropdown.Item
                    key={sectionTitle}
                    className={cx(
                      styles.subheaderMenuItem,
                      styles.subheaderBorder,
                    )}
                    href={`${newVehicleMovesPath()}#${titleToAnchorId(
                      sectionTitle,
                    )}`}
                  >
                    {sectionTitle}
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown as={NavItem} show={openPersonal || personalMenuHover}>
            <Dropdown.Toggle
              as={NavLink}
              onMouseEnter={() => setOpenPersonal(true)}
              onMouseLeave={() => setOpenPersonal(false)}
              onKeyDown={() => setOpenPersonal(true)}
              onKeyUp={() => setOpenPersonal(false)}
              className={cx(
                styles.subheaderLink,
                styles.subheaderTopLevelMenu,
                [
                  "individual-vehicle-moves",
                  "specialty-vehicle-moves",
                ].includes(activePage) && styles.activeHeader,
              )}
            >
              Personal <br />
              Vehicle Moves
            </Dropdown.Toggle>
            <Dropdown.Menu
              className={cx(styles.subheaderMenuList, "py-0")}
              onMouseEnter={() => setPersonalMenuHover(true)}
              onMouseLeave={() => setPersonalMenuHover(false)}
            >
              <Dropdown.Item
                key="individual"
                className={cx(styles.subheaderMenuItem, styles.subheaderBorder)}
                href={individualVehicleMovesPath()}
              >
                Individual Moves
              </Dropdown.Item>
              <Dropdown.Item
                key="specialty"
                className={styles.subheaderMenuItem}
                href={specialtyVehicleMovesPath()}
              >
                Specialty Moves
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <OutboundLink
            href={haullyURL()}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.subheaderLink}
          >
            <Imgix
              src={haullyLogo.url}
              alt="Haully Logo"
              className={styles.brandLogo}
              width={95}
            />
          </OutboundLink>
          <OutboundLink
            href={teamDriveAwayURL()}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.subheaderLink}
          >
            <Imgix
              src={teamDriveAwayLogo.url}
              alt="Team Drive Away Logo"
              className={styles.brandLogo}
              width={95}
            />
          </OutboundLink>
          <OutboundLink
            href={pilotTransportURL()}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.subheaderLink}
          >
            <Imgix
              src={pilotTransportLogo.url}
              alt="Pilot Transport Logo"
              className={styles.brandLogo}
              width={95}
            />
          </OutboundLink>
        </Navbar>
      </div>
    </div>
  )
}
