import React from "react"
import { Alert } from "react-bootstrap"
import styles from "./stylesheets/thankYouAlert.module.scss"
import cx from "classnames"

export default function ThankYouAlert() {
  return (
    <div className="border-0 pt-0">
      <Alert className={cx(styles.thankYouAlert, "px-md-4 mb-5 py-4")}>
        <p>
          <strong>Thank you!</strong> We appreciate your comments and feedback.
          We will be in touch with you soon.
        </p>
      </Alert>
    </div>
  )
}
