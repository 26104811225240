import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { withPreview } from "gatsby-source-prismic-graphql"
import Helmet from "react-helmet"

const seoQuery = graphql`
  query DefaultSEOQuery {
    prismic {
      allHomepages {
        edges {
          node {
            title
            homepage_meta_title
            homepage_meta_description
          }
        }
      }
    }
  }
`

const SEORenderer = ({ data, metaDescription, metaTitle, lang, meta }) => {
  const seoData = data.prismic && data.prismic.allHomepages.edges[0]?.node
  if (!seoData) return null

  const site = {
    siteMetadata: {
      title: "United Road",
      description: seoData.homepage_meta_description[0]?.text,
      author: "United Road",
    },
  }

  const description = metaDescription || site.siteMetadata.description
  const title = metaTitle || site.siteMetadata.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `google-site-verification`,
          content: `UsGuYQKCRWlJW_HCS0siseYUwt-khNQFgpjzLWuyZho`,
        },
        {
          name: `facebook-domain-verification`,
          content: `p20t41vso1g7fpbib6e721txhh5zpk`,
        },
      ].concat(meta)}
    />
  )
}

export default function SEO({ metaTitle, metaDescription }) {
  return (
    <StaticQuery
      query={`${seoQuery}`}
      render={withPreview(
        (data) => (
          <SEORenderer
            data={data}
            metaTitle={metaTitle}
            metaDescription={metaDescription}
            lang={`en`}
            meta={[]}
          />
        ),
        seoQuery,
      )}
    />
  )
}
