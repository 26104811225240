import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Imgix from "react-imgix"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { withPreview } from "gatsby-source-prismic-graphql"
import { ReactComponent as FacebookIcon } from "../images/facebook-icon.svg"
import { ReactComponent as TwitterIcon } from "../images/twitter-icon.svg"
import { ReactComponent as LinkedInIcon } from "../images/linked-in-icon.svg"
import { ReactComponent as YoutubeIcon } from "../images/youtube-icon.svg"
import {
  aboutUsPath,
  homePath,
  siteMapPath,
  privacyPolicyPath,
  employeeResourcesPath,
  mediaCenterPath,
  onlineStoreURL,
  trucksForSaleURL,
  facebookURL,
  twitterURL,
  linkedInURL,
  youTubeURL,
  citrixLoginURL,
} from "../paths"
import * as paths from "../paths"

import styles from "./stylesheets/footer.module.scss"
import cx from "classnames"

const footerQuery = graphql`
  query FooterQuery {
    prismic {
      allHomepages {
        edges {
          node {
            footer_logo
            footer_phone_numbers
            footer_address
          }
        }
      }
    }
  }
`

const FooterRenderer = ({ data }) => {
  const headerData = data.prismic && data.prismic.allHomepages.edges[0]
  if (!headerData) return null

  const { footer_logo, footer_phone_numbers, footer_address } = headerData.node

  return (
    <footer className="p-md-5 bg-dark text-white">
      <div
        className={cx(
          styles.topLevelContainer,
          "d-flex flex-row justify-content-between align-items-around",
        )}
      >
        <div
          className={cx(
            styles.fontMediumLarge,
            "d-none d-md-flex flex-column justify-content-between",
          )}
        >
          <Link to={homePath()}>
            <Imgix
              src={footer_logo.url}
              alt={footer_logo.alt}
              className={styles.footerLogo}
              width={320}
            />
          </Link>
          <div className="mb-3">
            {footer_address.map((address_line) => {
              return <div key={address_line.text}>{address_line.text}</div>
            })}
          </div>
          <div className="font-weight-bold">
            {footer_phone_numbers.map((phone_number) => {
              return (
                <a href={`tel:${phone_number.text}`} key={phone_number.text}>
                  {phone_number.text}
                </a>
              )
            })}
          </div>
        </div>
        <div className="d-none d-md-flex flex-column justify-content-end text-center">
          <FooterIcons />
          <div>
            <FooterSubLinks />
          </div>
        </div>
        <div
          className={cx(
            styles.fontLarge,
            "d-none d-md-flex flex-column justify-content-between text-right",
          )}
        >
          <FooterMainLinks />
          <div className={styles.fontMediumSmall}>
            ©{new Date().getFullYear()} Copyright United Road Services. <br />
            All Rights Reserved.
          </div>
        </div>

        {/* MOBILE */}
        <div className="d-md-none d-flex flex-column text-center justify-content-between w-100 text-right pt-4">
          <FooterMainLinks />
          <div className={cx(styles.mobilePhoneNumber, "my-4")}>
            {footer_phone_numbers.map((phone_number) => {
              return (
                <a href={`tel:${phone_number.text}`} key={phone_number.text}>
                  {phone_number.text}
                </a>
              )
            })}
          </div>
          <div className="mb-2">
            <FooterIcons />
          </div>
          <div
            className={cx(
              styles.fontMediumSmall,
              styles.borderGray,
              "border-bottom border-top p-2",
            )}
          >
            <FooterSubLinks />
          </div>
          <div className={cx(styles.fontMediumSmall, "my-3")}>
            ©{new Date().getFullYear()} Copyright United Road Services. <br />
            All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  )
}

const FooterMainLinks = () => {
  return (
    <div className="d-flex flex-column mb-3">
      <OutboundLink
        href={onlineStoreURL()}
        target="_blank"
        rel="noopener noreferrer"
        className="font-weight-bold"
      >
        United Road Store
      </OutboundLink>
      <Link to={employeeResourcesPath()} className="font-weight-bold">
        Employee Resources
      </Link>
      <Link to={aboutUsPath()} className="font-weight-bold">
        About Us
      </Link>
      <OutboundLink
        href={trucksForSaleURL()}
        target="_blank"
        rel="noopener noreferrer"
        className="font-weight-bold"
      >
        Trucks for Sale
      </OutboundLink>
      <OutboundLink
        href={citrixLoginURL()}
        target="_blank"
        rel="noopener noreferrer"
        className="font-weight-bold"
      >
        Citrix Login
      </OutboundLink>
      <Link to={mediaCenterPath()} className="font-weight-bold">
        Media Center
      </Link>
    </div>
  )
}

const FooterSubLinks = () => {
  return (
    <div>
      <Link to={privacyPolicyPath()}>Privacy Policy</Link>
      <span className="px-2">|</span>
      <Link to={siteMapPath()}>Site Map</Link>
      <span className="px-2">|</span>
      <Link to={paths.termsOfUse()}>Terms of Use</Link>
      <span className="px-2">|</span>
      <Link to={paths.termsAndConditionsPath()}>Terms and Conditions</Link>
    </div>
  )
}

const FooterIcons = () => {
  return (
    <div className={styles.footerIcons}>
      <OutboundLink
        href={facebookURL()}
        className="px-3"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Facebook"
      >
        <FacebookIcon />
      </OutboundLink>
      <OutboundLink
        href={twitterURL()}
        className="px-3"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Twitter"
      >
        <TwitterIcon />
      </OutboundLink>
      <OutboundLink
        href={linkedInURL()}
        className="px-3"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="LinkedIn"
      >
        <LinkedInIcon />
      </OutboundLink>
      <OutboundLink
        href={youTubeURL()}
        className="px-3"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="YouTube"
      >
        <YoutubeIcon />
      </OutboundLink>
    </div>
  )
}

export default function Footer() {
  return (
    <StaticQuery
      query={`${footerQuery}`}
      render={withPreview(
        (data) => (
          <FooterRenderer data={data} />
        ),
        footerQuery,
      )}
    />
  )
}
