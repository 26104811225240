import cx from "classnames"
import { Link } from "gatsby"
import React from "react"
import { Button, Dropdown, Navbar } from "react-bootstrap"
import Imgix from "react-imgix"
import { ReactComponent as Hamburger } from "../images/hamburger.svg"
import {
  aboutUsPath,
  careersPath,
  contactUsPath,
  customerPortalUrl,
  deliveryReceiptPath,
  haullyURL,
  homePath,
  individualVehicleMovesPath,
  leadershipTeamPath,
  loginURL,
  newVehicleMovesPath,
  pilotTransportURL,
  remarketedVehicleMovesPath,
  specialtyVehicleMovesPath,
  teamDriveAwayURL,
} from "../paths"
import { titleToAnchorId } from "../utils/titleToAnchorId"
import styles from "./stylesheets/mobileHeader.module.scss"

export default function MobileHeader({
  unitedRoadLogo,
  remarketedVehicleSectionTitles,
  newVehicleSectionTitles,
}) {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const [showRemarketed, setShowRemarketed] = React.useState(false)
  const [showPersonal, setShowPersonal] = React.useState(false)
  const [showNewVehicle, setShowNewVehicle] = React.useState(false)

  return (
    <Navbar
      className={cx(
        styles.mobileHeaderContainer,
        "d-flex d-lg-none justify-content-center align-items-center",
      )}
    >
      <div className={styles.hamburgerContainer}>
        <Dropdown onToggle={() => setDropdownOpen(!dropdownOpen)}>
          <Dropdown.Toggle
            className={cx(styles.hamburgerButton, "shadow-none")}
            aria-label="Menu"
          >
            <Hamburger />
          </Dropdown.Toggle>
          <Dropdown.Menu className={styles.mobileMenuList} show={dropdownOpen}>
            <div
              className={cx(
                !showRemarketed && styles.mobileHeaderBorder,
                "d-flex",
              )}
            >
              <Dropdown.Item
                key={"remarketed-vehicle-moves"}
                className={cx(
                  styles.mobileHeaderItem,
                  styles.mobileHeaderTopLevel,
                )}
                href={remarketedVehicleMovesPath()}
              >
                Remarketed Vehicle Moves
              </Dropdown.Item>
              <Button
                className={cx(styles.caret, "shadow-none")}
                onClick={() => {
                  setShowRemarketed(!showRemarketed)
                }}
              >
                ▼
              </Button>
            </div>
            {showRemarketed &&
              remarketedVehicleSectionTitles.map((sectionTitle) => {
                return (
                  <Dropdown.Item
                    key={sectionTitle}
                    className={styles.mobileHeaderSubItem}
                    href={`${remarketedVehicleMovesPath()}#${titleToAnchorId(
                      sectionTitle,
                    )}`}
                  >
                    {sectionTitle}
                  </Dropdown.Item>
                )
              })}
            <div
              className={cx(
                !showRemarketed && styles.mobileHeaderBorder,
                "d-flex",
              )}
            >
              <Dropdown.Item
                key={"new-vehicle-moves"}
                className={cx(
                  styles.mobileHeaderItem,
                  styles.mobileHeaderTopLevel,
                )}
                href={newVehicleMovesPath()}
              >
                New Vehicle Moves
              </Dropdown.Item>
              <Button
                className={cx(styles.caret, "shadow-none")}
                onClick={() => {
                  setShowNewVehicle(!showNewVehicle)
                }}
              >
                ▼
              </Button>
            </div>
            {showNewVehicle && (
              <Dropdown.Item
                key="oem"
                className={styles.mobileHeaderSubItem}
                href={newVehicleMovesPath()}
              >
                OEM
              </Dropdown.Item>
            )}
            {showNewVehicle &&
              newVehicleSectionTitles.map((sectionTitle) => {
                return (
                  <Dropdown.Item
                    key={sectionTitle}
                    className={styles.mobileHeaderSubItem}
                    href={`${newVehicleMovesPath()}#${titleToAnchorId(
                      sectionTitle,
                    )}`}
                  >
                    {sectionTitle}
                  </Dropdown.Item>
                )
              })}

            <div
              className={cx(
                !showPersonal && styles.mobileHeaderBorder,
                "d-flex justify-content-start",
              )}
            >
              <Button
                key={"personal-vehicle-moves"}
                className={cx(
                  styles.mobileHeaderItem,
                  styles.mobileHeaderTopLevel,
                )}
                onClick={() => setShowPersonal(!showPersonal)}
              >
                Personal Vehicle Moves
              </Button>
              <Button
                className={cx(styles.caret, "shadow-none ml-auto")}
                onClick={() => {
                  setShowPersonal(!showPersonal)
                }}
              >
                ▼
              </Button>
            </div>
            {showPersonal && (
              <div>
                <Dropdown.Item
                  key="Individual"
                  className={cx(styles.mobileHeaderSubItem)}
                  href={individualVehicleMovesPath()}
                >
                  Individual Moves
                </Dropdown.Item>
                <Dropdown.Item
                  key="Specialty"
                  className={styles.mobileHeaderSubItem}
                  href={specialtyVehicleMovesPath()}
                >
                  Specialty Moves
                </Dropdown.Item>
              </div>
            )}

            <Dropdown.Item
              key="Haully"
              className={cx(
                styles.mobileHeaderItem,
                styles.mobileHeaderBorder,
                styles.mobileHeaderTopLevel,
              )}
              target="_blank"
              rel="noopener noreferrer"
              href={haullyURL()}
            >
              Haully
            </Dropdown.Item>
            <Dropdown.Item
              key="NVTA"
              className={cx(
                styles.mobileHeaderItem,
                styles.mobileHeaderBorder,
                styles.mobileHeaderTopLevel,
              )}
              target="_blank"
              rel="noopener noreferrer"
              href={teamDriveAwayURL()}
            >
              NVTA
            </Dropdown.Item>
            <Dropdown.Item
              key="Pilot Transport"
              className={cx(
                styles.mobileHeaderItem,
                styles.mobileHeaderTopLevel,
              )}
              target="_blank"
              rel="noopener noreferrer"
              href={pilotTransportURL()}
            >
              Pilot Transport
            </Dropdown.Item>

            <Dropdown.Item
              key="Customer Portal"
              className={cx(
                styles.deliveryItem,
                styles.mobileHeaderTopLevel,
                "text-white shadow-none",
              )}
              target="_blank"
              rel="noopener noreferrer"
              href={customerPortalUrl()}
            >
              Customer Portal
            </Dropdown.Item>

            <Dropdown.Item
              key="Log in"
              className={cx(
                styles.mobileHeaderNavItem,
                styles.mobileHeaderItem,
                styles.mobileHeaderBorder,
              )}
              href={loginURL()}
              target="_blank"
              rel="noopener noreferrer"
            >
              OVISS Login
            </Dropdown.Item>
            <Dropdown.Item
              key="About United Road"
              className={cx(
                styles.mobileHeaderNavItem,
                styles.mobileHeaderItem,
                styles.mobileHeaderBorder,
              )}
              href={aboutUsPath()}
            >
              About United Road
            </Dropdown.Item>
            <Dropdown.Item
              key="Leadership Team"
              className={cx(
                styles.mobileHeaderNavItem,
                styles.mobileHeaderItem,
                styles.mobileHeaderBorder,
              )}
              href={leadershipTeamPath()}
            >
              Leadership Team
            </Dropdown.Item>
            <Dropdown.Item
              key="Careers"
              className={cx(
                styles.mobileHeaderNavItem,
                styles.mobileHeaderItem,
                styles.mobileHeaderBorder,
              )}
              href={careersPath()}
            >
              Careers
            </Dropdown.Item>
            <Dropdown.Item
              key="Contact Us"
              className={cx(
                styles.mobileHeaderNavItem,
                styles.mobileHeaderItem,
              )}
              href={contactUsPath()}
            >
              Contact Us
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div>
        <Link to={homePath()}>
          <Imgix
            className={styles.mobileLogo}
            src={unitedRoadLogo.url}
            alt={unitedRoadLogo.alt}
            width={165}
          />
        </Link>
      </div>
    </Navbar>
  )
}
