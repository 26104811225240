import PropTypes from "prop-types"
import React from "react"
import { Modal, Button } from "react-bootstrap"
import Header from "./header"
import Footer from "./footer"
import ThankYouAlert from "./thankYouAlert"
import "../stylesheets/global.scss"
import styles from "./stylesheets/layout.module.scss"
import cx from "classnames"

const Layout = ({ children, activePage, bottomBorder }) => {
  const [show, setShow] = React.useState(false)
  const handleClose = () => setShow(false)

  return (
    <>
      <Header activePage={activePage} bottomBorder={bottomBorder} />
      {show && (
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton className="border-0 pt-5 px-0 mx-4 mx-md-5">
            <div className="d-flex flex-column">
              <Modal.Title className={cx("mb-3", styles.title)}>
                Get in touch today!
              </Modal.Title>
              <h5 className={styles.subtitle}>
                Fill out the form below to get a quote.
              </h5>
            </div>
          </Modal.Header>
          <div className="px-0 px-md-5">
            <ThankYouAlert />
          </div>
          <Modal.Footer className="border-0 mr-4">
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
